import { useCallback, useRef } from 'react';
import { carryParams } from 'utils';

import { useNavigate } from 'hooks/useNavigate';
import { importStoreActions } from 'modules/library/stores/importStore/actions';
import { useMainPageStore } from 'modules/mainPage/stores/mainPageStore';
import { mainPageStoreActions } from 'modules/mainPage/stores/mainPageStore/actions';
import { setTimeToPlay } from 'utils/analytics';

import { getRecordType } from '../utils';

export function useListenFile(): (itemId: string, mimeType: string, isJustImported?: boolean) => void {
  const navigate = useNavigate();
  const instantListening = useMainPageStore(s => s.instantListening);
  const instantListeningRef = useRef(instantListening);

  const handleListenClick = useCallback(
    (itemId: string, mimeType: string, isJustImported?: boolean) => {
      importStoreActions.closeImportDialog();

      if (!isJustImported) {
        setTimeToPlay('open-item', getRecordType(mimeType));
        navigate(carryParams(`/item/${itemId}`));
      } else {
        if (instantListeningRef.current) {
          history.pushState({}, '', `/item/${itemId}`);
        }

        mainPageStoreActions.setNewlyImportedItemId(itemId);
      }
    },
    [navigate]
  );

  return handleListenClick;
}
