import { useCallback, useRef, useState } from 'react';

import { IntegrationAuthResponse, IntegrationService } from 'interfaces/integrations';
import { useIntegrationStore } from 'modules/integration/stores/integrationStore';
import { integrationStoreActions } from 'modules/integration/stores/integrationStore/actions';
import { integrationStoreSelectors } from 'modules/integration/stores/integrationStore/selectors';

export function useIntegrationAuth(service: IntegrationService): [IntegrationAuthResponse, boolean, (options?: Record<string, string>) => Promise<boolean>] {
  const authorizeData = useIntegrationStore(integrationStoreSelectors.getAuthByService(service));
  const [isAuthorizing, setIsAuthorizing] = useState(false);

  const authorizedRef = useRef(authorizeData.authorized);
  authorizedRef.current = authorizeData.authorized;

  const authorize = useCallback(
    async (options?: Record<string, string>): Promise<boolean> => {
      if (!authorizedRef.current) {
        setIsAuthorizing(true);

        let auth = await integrationStoreActions.authorizeService(service);

        if (!auth.authorized) {
          const childWindow = window.open(auth.authorizationUrl, `${service}_auth_popup`, '_blank');
          let intervalId: NodeJS.Timeout | undefined = undefined;

          await new Promise<boolean>(resolve => {
            intervalId = setInterval(() => {
              try {
                if (childWindow?.closed) {
                  clearInterval(intervalId);
                  resolve(true);
                }
              } catch (e) {
                // do nothing
              }
            }, 1000);
          });

          auth = await integrationStoreActions.authorizeService(service, options);
          setIsAuthorizing(false);
          return auth.authorized;
        } else {
          return true;
        }
      }

      return true;
    },
    [service]
  );

  return [authorizeData, isAuthorizing, authorize];
}
