import { IntegrationAuthResponse, IntegrationFile, IntegrationService } from 'interfaces/integrations';
import { SORT_BY_FIELD, SORT_ORDER } from 'interfaces/integrations';
import { IntegrationState } from 'store/integration/types';

const unauthorizedIntegration: IntegrationAuthResponse = { authorized: false };

export const integrationStoreSelectors = {
  getAuthByService: (service: IntegrationService) => (state: IntegrationState) => state.auths[service] ?? unauthorizedIntegration,

  getSortBy: (state: IntegrationState) => state.sortBy ?? SORT_BY_FIELD.CREATED_TIME,

  getSortOrder: (state: IntegrationState) => state.sortOrder ?? SORT_ORDER.DESC,

  getImportedItemId: (service: IntegrationService, file: IntegrationFile) => (state: IntegrationState) => {
    const key = `${file.id}_${file.sizeBytes}`;
    const importedItems = state.importedItems || {};
    return importedItems[service]?.[key] ?? null;
  },

  isSuggestionEnabled: (service: IntegrationService) => (state: IntegrationState) => !state.suggestionDisabled?.[service]
};
