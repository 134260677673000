import { authorize, fetchSuggestedItems, getAuths, logout } from 'components/library/import/api';

import { IntegrationAuthResponse, IntegrationFile, IntegrationService, SORT_BY_FIELD, SORT_ORDER } from 'interfaces/integrations';
import { CanvasIntegrationCredentials } from 'store/integration/types';

import { useIntegrationStore } from '.';

const unauthorizedIntegration: IntegrationAuthResponse = { authorized: false };

export const integrationStoreActions = {
  setOrder: ({ sortBy, sortOrder }: { sortBy: SORT_BY_FIELD; sortOrder: SORT_ORDER }) => {
    useIntegrationStore.setState(state => ({
      ...state,
      sortBy,
      sortOrder
    }));
  },

  setFilter: (filter: string | undefined) => {
    useIntegrationStore.setState(state => ({
      ...state,
      filter
    }));
  },

  setFileImported: (service: IntegrationService, file: IntegrationFile, itemId: string) => {
    useIntegrationStore.setState(state => ({
      ...state,
      importedItems: {
        ...state.importedItems,
        [service]: {
          ...(state.importedItems[service] || {}),
          [`${file.id}_${file.sizeBytes}`]: itemId
        }
      }
    }));
  },

  showTooltip: (service: IntegrationService) => {
    useIntegrationStore.setState(state => ({
      ...state,
      showTooltip: { ...state.showTooltip, [service]: true }
    }));
  },

  hideTooltip: (service: IntegrationService) => {
    useIntegrationStore.setState(state => ({
      ...state,
      showTooltip: { ...state.showTooltip, [service]: false }
    }));
  },

  setCanvasData: (data: CanvasIntegrationCredentials | null) => {
    useIntegrationStore.setState(state => ({
      ...state,
      [IntegrationService.CANVAS]: data
    }));
  },

  disableSuggestions: (service: IntegrationService) => {
    useIntegrationStore.setState(state => ({
      ...state,
      suggestionDisabled: {
        ...state.suggestionDisabled,
        [service]: true
      }
    }));
  },

  enableSuggestions: (service: IntegrationService) => {
    useIntegrationStore.setState(state => ({
      ...state,
      suggestionDisabled: {
        ...state.suggestionDisabled,
        [service]: false
      }
    }));
  },

  fetchAuths: async () => {
    useIntegrationStore.setState(state => ({ ...state, isLoading: true }));
    try {
      const auths = await getAuths();
      useIntegrationStore.setState(state => ({
        ...state,
        auths,
        isLoading: false
      }));
      return auths;
    } catch (error) {
      useIntegrationStore.setState(state => ({ ...state, isLoading: false }));
      throw error;
    }
  },

  fetchSuggestions: async () => {
    useIntegrationStore.setState(state => ({ ...state, isLoading: true }));
    try {
      const services = Object.entries(useIntegrationStore.getState().auths)
        .map(([service, { authorized }]) => (authorized ? (service as IntegrationService) : null))
        .filter<IntegrationService>((service): service is IntegrationService => !!service && !useIntegrationStore.getState().suggestionDisabled?.[service]);

      const suggestions = await fetchSuggestedItems(services, 1);
      useIntegrationStore.setState(state => ({
        ...state,
        suggestedItems: suggestions,
        isLoading: false
      }));
      return suggestions;
    } catch (error) {
      useIntegrationStore.setState(state => ({ ...state, isLoading: false }));
      throw error;
    }
  },

  authorizeService: async (service: IntegrationService, options?: Record<string, string>) => {
    useIntegrationStore.setState(state => ({ ...state, isLoading: true }));
    try {
      const authResponse = await authorize(service, options);

      useIntegrationStore.setState(state => ({
        ...state,
        auths: {
          ...state.auths,
          [service]: authResponse
        },
        showTooltip: authResponse.authorized ? { ...state.showTooltip, [service]: true } : state.showTooltip,
        isLoading: false
      }));
      return authResponse;
    } catch (error) {
      useIntegrationStore.setState(state => ({ ...state, isLoading: false }));
      throw error;
    }
  },

  logoutFromService: async (service: IntegrationService) => {
    useIntegrationStore.setState(state => ({ ...state, isLoading: true }));
    try {
      const result = await logout(service);
      if (result.logout) {
        useIntegrationStore.setState(state => ({
          ...state,
          auths: {
            ...state.auths,
            [service]: unauthorizedIntegration
          },
          isLoading: false
        }));
      } else {
        useIntegrationStore.setState(state => ({ ...state, isLoading: false }));
      }
      return result;
    } catch (error) {
      useIntegrationStore.setState(state => ({ ...state, isLoading: false }));
      throw error;
    }
  }
};
