import { createLocalStorageStore } from 'lib/zustand/store';
import { IntegrationState } from 'store/integration/types';

type PersistedIntegrationState = Omit<IntegrationState, 'isLoading'>;

const initialState: IntegrationState = {
  auths: {},
  isLoading: false,
  importedItems: {},
  suggestedItems: [],
  showTooltip: {}
};

export const useIntegrationStore = createLocalStorageStore<IntegrationState, PersistedIntegrationState>(() => initialState, {
  storageName: 'speechifyIntegration',
  version: 1,
  partialize: (state: IntegrationState) => {
    const { isLoading, ...persistedState } = state;
    return persistedState;
  },
  backfillStateFromReduxPersist(reduxPersistedState) {
    return {
      ...reduxPersistedState.integration,
      isLoading: false
    };
  }
});
